<template>
      
  <div>
    <!-- 相关链接 -->
    <!-- <aside class="action">
      <div class="span">
          首页
      </div>
      &nbsp;
      <img src="../assets/img/InforCenter/juxing14.png" alt="" />
      &nbsp;
      <div class="span">
          相关链接
      </div>

    </aside> -->
    <mainBox></mainBox>

    <div class="header">相关链接</div>
    <div class="body">
      <div class="children">
        <div class="childLeft">
          <img src="../assets/link/linkOne.png" alt="" />
        </div>
        <div class="childRight">
          <div class="childrenHeader">顺义文化馆</div>
          <div class="text">
            <span
              >文化活动中心是开展群众文化活动，并给群众文娱活动提供场所的机构</span
            >
          </div>
          <a href="https://www.sywhg.org.cn/" target="_blank"><div class="btnes">进入专馆</div></a>
        </div>
      </div>
      <div class="childrens">
        <div class="childLefts">
          <img src="../assets/link/shunyilibrary.png" alt="" />
        </div>
        <div class="childRights">
          <div class="childrenHeader">顺义图书馆</div>
          <div class="text">
            <span
              >图书馆服务工作是一项开发利用图书馆资源的工作。它包括发展读者 (如发放借书证)、读者研究、文献流通和
              推广服务包括文献外借、阅览服务...</span
            >
          </div>
          <a href="http://www.bjsylib.com.cn/" target="_blank"><div class="btns">进入专馆</div></a>
        </div>
      </div>
      <div class="children">
        <div class="childLeft">
          <img src="../assets/link/shunyiMovie.png" alt="" />
        </div>
        <div class="childRight">
          <div class="childrenHeader">顺义影剧院</div>
          <div class="text">
            <span
              >一个播放文化、艺术、生活的介质，也是一个沟通世界的桥梁；供放映电影、演出戏剧、歌舞、曲艺等的场所...</span
            >
          </div>
          <div class="btnes">进入专馆</div>
        </div>
      </div>
    </div>
       <Footer></Footer>
  </div>
</template>

<script>
import Footer from '../components/footer'
import { fetch } from '../api/https';
import mainBox from "@/components/main.vue";

export default {
  data() {
    return {
        list:[]
    };
  },
  components:{
    Footer,mainBox
  },
  created() {},
  mounted() {
    fetch("/getFootMenu").then((res) => {
        document.title = res.data[0].menu_seo_title;
      });
  },
  methods: {},
};
</script>

<style scoped>
.action {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  font-size: 14px;
  box-sizing: border-box;
  margin-top: 54px;
  margin-bottom: 51px;
  height: 14px;
  /* font-family: Microsoft YaHei; */
  font-weight: 300;
  color: #000000;
}
.action .span{
  font-family: MicrosoftYaHeiLight;
  float: left;
}
.action img{
  float: left;
  margin-top: 3px;
  height: 13px;
  width: 12px;
  margin-left: 6px;
  margin-right: 9px;
}
.header {
  max-width: 1400px;
  margin: 0 auto;
  height: 23px;
  background: url("../assets/zu74.png") no-repeat center;
  text-align: center;
  font-size: 24px;
  color: #b87646;
  margin-bottom: 30px;
  line-height: 23px;
  font-weight: 400;
}
.body {
  width: 1447px;
  margin: 0 auto;
  height: auto;
  background-size: 100% 100%;
    padding-top: 116px;
  padding-left: 123px;
  box-sizing: border-box;
  background: url("../assets/link/allBack.png") no-repeat;
  padding-bottom: 109px;
  margin-bottom: 46px;
}
.children {
  width: 1202px;
  height: 428px;
  padding: 40px;
  box-sizing: border-box;
  background: url('../assets/link/childrenBack.png') no-repeat;
  background-size: 100% 100%;
  margin-bottom: 40px;
}
.childrens {
  width: 1200px;
  height: 430px;
  padding: 40px;
  padding-left: 59px;
  box-sizing: border-box;
  background: url('../assets/link/childrenBack.png') no-repeat;
  background-size: 100% 100%;
  margin-bottom: 40px;
}
.childLeft {
  float: left;
  margin-right: 42px;
  width: 520px;
  height: 347px;
}
.childLeft img {
  height: 347px;
  width: 520px;
  margin-right: 42px;
}
.childLefts {
  float: right;
}
.childLeftw img {
  height: 347px;
  width: 520px;
  margin-right: 40px;
}
.childRight {
    position: relative;
  float: left;
  height: 347px;
  /* background: seagreen; */
  /* margin-top: 16px; */
  padding: 16px 0 10px 0;
  box-sizing: border-box;
}
.childRights {
    position: relative;
  float: left;
  height: 347px;
  width: 520px;
  /* background: seagreen; */
  /* margin-top: 16px; */
  padding: 16px 0;
  padding-left: 21px;
  box-sizing: border-box;
}
.childRights {
    position: relative;
  float: left;
  height: 347px;
  width: 520px;
  /* background: seagreen; */
  /* margin-top: 16px; */
  padding: 16px 0;
  box-sizing: border-box;
}
.childrenHeader {
  /* width: 139px; */
  /* height: 28px; */
  font-size: 28px;
  font-weight: 400;
  color: #9b7d57;
  line-height: 33px;
}
.text {
  margin-top: 30px;
  width: 468px;
  height: 54px;
  font-size: 18px;
  font-weight: 400;
  color: #666666;
  line-height: 36px;
  font-family: Microsoft YaHei;
}
.btn {
  width: 170px;
  height: 49px;
  background: #ca1f04;
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 49px;
  position:absolute;
  bottom: 16px;
  left: 21px;
}
.btnes {
  width: 170px;
  height: 49px;
  background: #ca1f04;
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 49px;
  position:absolute;
  bottom: 10px;
}
.btns {
  width: 170px;
  height: 49px;
  background: #ca1f04;
  color: white;
  font-size: 18px;
  text-align: center;
  line-height: 50px;
  position:absolute;
  bottom: 10px;
  left: 0px;
}
@media screen and (max-width: 1440px){
  .header{
    max-width: 1266px;
  }
  .body{
        width: 1300px;
    margin: 0 auto;
    height: auto;
    background-size: 100% 100%;
    padding-top: 116px;
    padding-left: 123px;
    box-sizing: border-box;
    background: url(/img/allBack.f980f998.png) no-repeat;
    background-size: 1300px 100%;
    padding-bottom: 109px;
    margin-bottom: 46px;
  }
  .childrens{
    width: 1050px;
  }
  .childRights{
    width: 400px;
  }
  .children{
    width: 1050px;
  }
  .childRight{
    width: 400px;
  }
  .text{
    width: 400px;
  }
}
</style>
